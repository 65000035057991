import React, { useEffect, useState } from 'react';
import './TagSection.scss';
import InfoHeader from '../InfoHeader/InfoHeader';
import Tag from './Tag';

export default function TagSection({ tags }) {
    const [rand, setRand] = useState([]);
    function random() {
        return Math.floor(Math.random() * 3) + 1;
    }

    useEffect(() => {
        const size = [];
        tags.forEach((_) => {
            size.push('tag-' + random());
            setRand(size);
        });
    }, [tags]);

    return (
        <section className="tag-section">
            <InfoHeader title={'Tags'} />
            <ul className="tags tag-page">
                {tags.map((tag, i) => (
                    <li key={tag.id}>
                        <Tag tag={tag} className={rand[i]} />
                    </li>
                ))}
            </ul>
        </section>
    );
}
