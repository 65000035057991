import React from "react";
import Layout from "component/Layout/Layout";
import { graphql } from "gatsby";
import Seo from "component/seo";
import TagSection from "component/Tag/TagSection";
import SidebarLeft from "component/Sidebar/SidebarLeft";
import SidebarRight from "component/Sidebar/SidebarRight";
import { useSiteUrl } from "utils";

export default function Tags({ data, location }) {
  const {
    allContentfulCategories: { nodes: tags },
  } = data;
  const canonicalUrl = `${useSiteUrl()}${location.pathname}`;

  return (
    <Layout>
      <SidebarLeft />
      <Seo titleSample={true} title="Tags" canonicalUrl={canonicalUrl} />
      <TagSection tags={tags} />
      <SidebarRight />
    </Layout>
  );
}

export const queryTags = graphql`
  {
    allContentfulCategories {
      nodes {
        id
        name
        url
      }
    }
  }
`;
